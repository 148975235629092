import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const mobileContainerStyle = () => css`
  padding: 0 20px;
  margin: 0 auto;
`

export const MobileContainer = styled.section`
  ${mobileContainerStyle}
`
