const formatNodes = nodes => {
  return Object.entries(nodes).reduce((acc, cur) => {
    console.log(cur[0], cur[1])
    const key = cur[0]
    const value = cur[1].childImageSharp.fluid || cur[1].childImageSharp.fixed

    acc[key] = value

    return acc
  }, {})
}

export default formatNodes
