const breakPoinnts = {
  sm: 576,
  md: 768,
  lg: 1240
}

const mq = Object.entries(breakPoinnts).reduce((acc, [name, size]) => {
  return { ...acc, [name]: `@media (min-width: ${size}px)` }
}, {})

export default mq
