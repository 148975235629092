import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const laptopContainerStyle = () => css`
  max-width: 1020px;
  padding: 0;
  margin: 0 auto;
`

export const LaptopContainer = styled.section`
  ${laptopContainerStyle}
`
